import { useMemo } from "react";
import { omit, pick } from "lodash";
import { createTypeEnum } from "shared/dashboard";
import { ENV } from "runenv";
import { getAllMatchingListsKey } from "shared/queryKeys";
import { isRbacError } from "shared/check-error";
import { useDynamicBackfillsSettings } from "shared/useDynamicBackfillsSettings";
import { hackishlyInsertDeviceTypeCondition } from "shared/deviceTypeHelper";
import {
  QueryClient,
  QueryFunctionContext,
  keepPreviousData,
  useInfiniteQuery,
  useIsFetching,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { CELConditionOperator, celCombine } from "libs/cel-query";
import { api, riskydashboard, types } from "api";
import { types_CategoryStatusEnum, types_SelectionTypeEnum, aggs, filtersapi } from "api/gen";
import {
  createCategory,
  createDataset,
  deleteCategory,
  deleteDataset,
  getCategoriesCounts,
  GetCategoriesCountsRequestType,
  listCategories,
  listDatasets,
  locationCount,
  updateCategory,
  updateDataset,
} from "api/risks";
import { logEvent, eventsConstants } from "modules/analytics/Analytics";
import { compareByProp } from "libs/data-utils";
import {
  AllCategories,
  AllCategoriesLabel,
  AllDatasets,
  AllDatasetsLabel,
  predefinedDatasetsTitle,
} from "modules/risks/shared";
import { MViewStatusKey } from "api/mview-status";
import { invalidateMultipleQueries } from "api/utils";
import { CustomPoliciesKey, PredefinedPoliciesKey } from "modules/content-rules/constants";
import { getEdmEntitiesKeys } from "modules/edm/edmConstants";
import { notification } from "modules/notification";
import {
  catergoriesFilterToPolicyFilterV2,
  datasetsFilterToDatasetFilterV2,
  previewDatasetToPreviewDatasetV2,
  previewPolicyToPreviewPolicyV2,
  processPreviewPolicy,
  EdgeView_Extended,
  selectedSensitivitiesToFilter,
  selectedSeveritiesToFilter,
} from "./utils";
import { Severity, severityList } from "../dashboard-core/severity";
import { isNullSensitivityAllowed } from "../dashboard-core/sensitivity";
import { UserSidePanelKeys } from "../users-table/apiHooks";
import { getUserNameCel } from "../shared/users";
import { useApiFiltersState } from "./state";
import { UseDatasetCounts } from "./types";

export const DataSetsKey = "datasets";
export const AggregationsKey = "aggregations";
export const DatasetCountsKey = "datasetCounts";
export const UserCountsKey = "usersCounts";
export const LocationsCountsKey = "locationsCounts";
export const CategoriesCountsKey = "categoriesCounts";
export const DataSetsAllKey = "datasets-all";
export const CategoriesKey = "categories";
export const CategoriesAllKey = "categories-all";
export const LocationsKey = "locations";
export const LocationsTotalKey = "locationsTotal";
export const UsersKey = "users";
export const TrendsKey = "trends";
export const StatusCountsKey = "statusCounts";
export const AdminEventsKey = "adminEvents";
export const UserManagementKey = "usm";
export const EdgesKey = "edges";

const getPreviewPolicyKey = (previewPolicy: { policy: aggs.PreviewPolicy } | null) => {
  if (!previewPolicy) {
    return previewPolicy;
  }

  return omit(previewPolicy?.policy, ["name", "description"]);
};

const getPreviewDatasetKey = (
  previewDataset: {
    dataset: types.Dataset;
    applied_policies: Array<types.Category | null> | null;
  } | null
) => {
  if (!previewDataset) {
    return previewDataset;
  }

  return {
    applied_policies: previewDataset.applied_policies?.map((p) => p?.id).filter((id) => id),
    ...omit(previewDataset.dataset, ["name", "description"]),
  };
};

const checkIfAllQueriesAreReady = (
  ...queries: Array<{ isFetching: boolean; isFetched: boolean; enabled?: boolean }>
) => {
  return queries.every(
    ({ isFetching, isFetched, enabled = true }) => !isFetching && isFetched && enabled
  );
};

const DATASET_NOT_NULL = "dataset_id != null";
export const getDatasetNullFilter = ({
  selectedSensitivities,
}: {
  selectedSensitivities?: number[] | null;
}) => {
  if (isNullSensitivityAllowed(selectedSensitivities!)) {
    return "";
  }

  return DATASET_NOT_NULL;
};

const getDatasetFilter = ({
  datasetFilter,
  previewPolicy,
  policyEdit,
  selectedSensitivities,
  datasetEdit,
}: {
  datasetFilter: types.DatasetsFilter | null;
  previewPolicy: {
    policy: {
      dataset_ids?: string[] | null;
    };
  } | null;
  policyEdit: { isSearch: boolean; isNew: boolean };
  datasetEdit: { isSearch: boolean; isNew: boolean };
  selectedSensitivities?: number[] | null;
}) => {
  if (!datasetFilter) {
    if (previewPolicy?.policy.dataset_ids?.length && !policyEdit.isSearch) {
      datasetFilter = {
        all_datasets: false,
        dataset_ids: previewPolicy?.policy.dataset_ids,
      };
    } else if (!policyEdit.isNew) {
      datasetFilter = {
        all_datasets: true,
        dataset_ids: null,
      };
    }
  }

  // If we search by source, we allow events from all datasets
  if (datasetEdit.isSearch) {
    datasetFilter = {
      all_datasets: true,
      dataset_ids: null,
    };
  }

  if (isNullSensitivityAllowed(selectedSensitivities!)) {
    if (!datasetFilter?.dataset_ids?.length) {
      // if not dataset ids are selected and null sensitivity is allowed, we return null so to-cel doesn't include dataset_id != null
      datasetFilter = null;
    }
  }
  return datasetFilter;
};

export function useUsersCounts({
  limit = 10,
  search,
  previewPolicy: previewPolicyProps,
  previewDataset: previewDatasetProps,
  isEnabled = true,
  extraAndFilter,
  itemQueries = true,
  filterOverride,
}: {
  limit: number;
  search: string;
  isEnabled?: boolean;
  itemQueries?: boolean;
  previewPolicy?: types.Category;
  previewDataset?: aggs.PreviewDataset;
  extraAndFilter?: string;
  filterOverride?: any;
}) {
  const { data: datasets = [] } = useDatasetListAll();
  const { policyEdit, datasetEdit, selectedSeverities, globalFilter, selectedHostnames, apiQuery } =
    useApiFiltersState();
  const usersFilter = pick(apiQuery.users_filter, "directory_users");
  const q = {
    ...apiQuery,
    users_filter: usersFilter,
    include_all_categories_record: false,
    include_uncategorized: true,
  };
  const categorySeverities = selectedSeverities || null;
  let categoriesFilter = catergoriesFilterToPolicyFilterV2(q.categories_filter);
  const previewPolicy = previewPolicyToPreviewPolicyV2(
    q.categories_filter?.preview_category,
    datasets.map((d) => d.id!) || []
  );
  const previewDataset = previewDatasetToPreviewDatasetV2(q.datasets_filter?.preview_dataset);
  let datasetFilter = datasetsFilterToDatasetFilterV2(q.datasets_filter);
  const selectedSensitivities = q.selected_sensitivities;
  const isPolicyUncategorizedSelected = categoriesFilter?.policy_ids?.includes("Uncategorized");

  datasetFilter = getDatasetFilter({
    datasetFilter,
    previewPolicy,
    policyEdit,
    selectedSensitivities,
    datasetEdit,
  });

  if (categoriesFilter && isPolicyUncategorizedSelected) {
    categoriesFilter = {
      all_policies: false,
      policy_ids: [null, ...(categoriesFilter?.policy_ids || [])],
    };
  }
  const { data: datesCel, ...dateCelQuery } = useDatesCel();

  const { data: filtersCel = "true", ...filtersQuery } = useFiltersCel({
    // times filter should be null, global times filter is applied in the query
    times_filter: null,
    locations_filters: q.locations_filters || null,
    users_filter: { ...q.users_filter, user_contains: search },
    side_selector: "",
    category_severity_filter: selectedSeveritiesToFilter(
      categorySeverities,
      isPolicyUncategorizedSelected
    ),
    dataset_sensitivity_filter: selectedSensitivitiesToFilter({
      sensitivities: selectedSensitivities,
      isUncategorizedIncluded: isPolicyUncategorizedSelected,
    }),
    datasets_filter: datasetFilter,
    policies_filter: categoriesFilter,
    status_filter: "any",
    hostnames_filter: [...selectedHostnames],
    ...filterOverride,
  });

  const { dynamicBackfillsQuery, dynamicBackfills } = useDynamicBackfillsSettings();

  const usersQuery = useInfiniteQuery({
    queryKey: [
      UserCountsKey,
      filtersCel,
      search,
      getPreviewDatasetKey(previewDataset),
      getEntityEditKey(policyEdit),
      getPreviewPolicyKey(previewPolicy),
      extraAndFilter,
      previewPolicyProps ? getPreviewPolicyKey({ policy: previewPolicyProps }) : "",
      omit(previewDatasetProps, ["name", "description"]),
      globalFilter,
      datesCel,
    ],
    queryFn: ({ pageParam = 0, signal }) => {
      const offset = pageParam as number;

      return api.eventsapi.Service.Aggregations(
        {
          global_filter: celCombine(
            CELConditionOperator.AND,
            getUserNameCel(search),
            datesCel!,
            getDatasetNullFilter({ selectedSensitivities }),
            globalFilter
          ),
          preview_dataset: hackishlyInsertDeviceTypeCondition(
            previewDatasetProps ||
              (previewDataset
                ? {
                    ...previewDataset?.dataset,
                    name: previewDataset?.dataset?.name!,
                    applied_policies: (previewDataset?.dataset as any)?.category_ids || [],
                  }
                : null)
          ),
          preview_policy: hackishlyInsertDeviceTypeCondition(
            previewPolicyProps ||
              (policyEdit?.isSearch || policyEdit.isNew || policyEdit.isEdit
                ? previewPolicy?.policy
                : null)
          ),
          aggregations: {
            users: {
              type: "group_by",
              group_by: ["local_user_name"],
              limit,
              filter: extraAndFilter
                ? celCombine(CELConditionOperator.AND, extraAndFilter, filtersCel)
                : filtersCel,
              item_queries:
                itemQueries && ENV.FEATURES.USER_WIDGET_DIRECTORY_DETAILS_ENABLED
                  ? {
                      ids: {
                        aggregation: "array_distinct",
                        query: "destination_endpoint_user_id",
                      },
                    }
                  : undefined,
              offset,
            },
          },
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ).then((res) => {
        const aggUsersRes = res.aggregations.users;
        const usersResponse = {
          complete_results: res.complete_results,
          records:
            aggUsersRes.values
              ?.filter((d) => Boolean(d.value))
              .map(({ value, count, item_queries }) => {
                const existingValue = value!;
                return {
                  user: existingValue[0],
                  endpoint_user_ids: item_queries?.ids || [],
                  count,
                };
              }) || [],
          total: aggUsersRes.cardinality!,
          offset,
          limit,
        };
        return usersResponse;
      });
    },
    enabled:
      isEnabled && checkIfAllQueriesAreReady(filtersQuery, dateCelQuery, dynamicBackfillsQuery),
    getNextPageParam: (lastPage) => {
      return lastPage.offset + limit;
    },
    initialPageParam: 0,
  });

  return {
    ...usersQuery,
    isLoading: filtersQuery.isLoading || usersQuery.isLoading || dateCelQuery.isLoading,
    isFetching: filtersQuery.isFetching || usersQuery.isFetching || dateCelQuery.isFetching,
    isFetched: filtersQuery.isFetched && usersQuery.isFetched && dateCelQuery.isFetched,
  };
}

export type PolicyCountData = {
  count: number;
  id: string;
  dataset_ids: string[];
};

export const useFiltersCel = (
  rawFilters: filtersapi.FilterToCelRequestFilters,
  enabled: boolean = true
) => {
  const filters = useMemo(() => {
    const f = { ...rawFilters };
    if (!f.policies_filter?.policy_ids?.length) {
      f.policies_filter = null;
    }

    if (!f.datasets_filter?.dataset_ids?.length && !f.datasets_filter?.all_datasets) {
      f.datasets_filter = null;
    }

    return f;
  }, [rawFilters]);

  const filtersQuery = useQuery({
    queryKey: ["filtersCel", rawFilters],

    queryFn: async ({ signal }) => {
      const res = await api.filtersapi.Service.FiltersToCel({ filters }, { signal });
      return res?.cel_string;
    },

    enabled,
  });

  return {
    ...filtersQuery,
    enabled,
  };
};

export function usePoliciesCounts(
  apiQuery: EdgeView_Extended & { category_severities?: Severity[] },
  enabled = true
) {
  const { policyEdit, datasetEdit, selectedCategories, globalFilter, selectedHostnames } =
    useApiFiltersState();
  const q = {
    ...omit(apiQuery, ["categories_filter.categories", "categories_filter.all_categories"]),
    statuses: [
      types_CategoryStatusEnum.CategoryStatusApproved,
      types_CategoryStatusEnum.CategoryStatusLowRisky,
      types_CategoryStatusEnum.CategoryStatusRisky,
    ],
    include_irrelevant: Boolean(apiQuery?.datasets_filter?.all_datasets),
    include_all_categories_record: false,
    include_uncategorized: true,
    is_search: policyEdit.isSearch,
    is_search_datasets: datasetEdit.isSearch,
  };
  const { data: datasets = [], ...datasetsQuery } = useDatasetListAll();
  const categorySeverities = q.category_severities || null;
  const policiesFilter = catergoriesFilterToPolicyFilterV2(q.categories_filter);
  const previewPolicy = previewPolicyToPreviewPolicyV2(
    q.categories_filter?.preview_category,
    datasets.map((d) => d.id!)
  );
  const previewDataset = previewDatasetToPreviewDatasetV2(q.datasets_filter?.preview_dataset);
  let datasetFilter = datasetsFilterToDatasetFilterV2(q.datasets_filter);

  const { data: categoriesRes = { categories: [] }, ...categoriesQuery } = useCategoryListAll();
  const { data: datesCel, ...dateCelQuery } = useDatesCel();
  const selectedSensitivities = q.selected_sensitivities;
  datasetFilter = getDatasetFilter({
    datasetFilter,
    previewPolicy,
    policyEdit,
    selectedSensitivities,
    datasetEdit,
  });
  const isUncategorizedIncluded = policiesFilter?.policy_ids?.includes("Uncategorized");
  const { data: filtersCel = "true", ...filtersQuery } = useFiltersCel({
    // times filter should be null, global times filter is applied in the query
    times_filter: null,
    locations_filters: q.locations_filters || null,
    users_filter: q.users_filter || null,
    side_selector: "category",
    category_severity_filter: selectedSeveritiesToFilter(
      categorySeverities,
      isUncategorizedIncluded
    ),
    dataset_sensitivity_filter: selectedSensitivitiesToFilter({
      sensitivities: selectedSensitivities,
      isUncategorizedIncluded,
    }),
    datasets_filter: datasetFilter,
    policies_filter: policiesFilter,
    status_filter: "any",
    hostnames_filter: [...selectedHostnames],
  });

  const { dynamicBackfills, dynamicBackfillsQuery } = useDynamicBackfillsSettings();
  const { categories } = categoriesRes;
  const countsQuery = useQuery({
    queryKey: [
      CategoriesCountsKey,
      filtersCel,
      getPreviewDatasetKey(previewDataset),
      apiQuery.datasets_filter.datasets,
      categories,
      getEntityEditKey(policyEdit),
      getPreviewPolicyKey(previewPolicy),
      datesCel,
      globalFilter,
    ],

    queryFn: ({ signal }) =>
      api.eventsapi.Service.Aggregations(
        {
          aggregations: {
            policies: {
              type: "group_by",
              filter: filtersCel,
              group_by: ["policy_id"],
            },
          },
          global_filter: celCombine(
            CELConditionOperator.AND,
            datesCel!,
            getDatasetNullFilter({ selectedSensitivities }),
            globalFilter
          ),
          preview_dataset: hackishlyInsertDeviceTypeCondition(
            previewDataset
              ? ({
                  ...previewDataset?.dataset,
                  name: previewDataset?.dataset?.name!,
                  applied_policies: (previewDataset?.dataset as any)?.category_ids || [],
                } as any)
              : null
          ),
          preview_policy: hackishlyInsertDeviceTypeCondition(
            policyEdit?.isSearch || policyEdit.isNew || policyEdit.isEdit
              ? previewPolicy?.policy
              : null
          ),
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ).then((res) => {
        const countsMap: Record<string, PolicyCountData> = {};
        for (const { count, value } of res.aggregations.policies.values || []) {
          const id = value?.[0] || "other";
          countsMap[id] = { count, id, dataset_ids: [] };
        }
        return { countsMap, complete_results: res.complete_results };
      }),

    enabled:
      enabled &&
      checkIfAllQueriesAreReady(
        datasetsQuery,
        categoriesQuery,
        dateCelQuery,
        filtersQuery,
        dynamicBackfillsQuery
      ),
  });
  const countsData = countsQuery.data?.countsMap || {};
  const datasetFilters = apiQuery.datasets_filter.datasets || [];
  const data = useMemo(() => {
    const countsMap: Record<string, PolicyCountData> = { ...countsData };

    datasets.forEach((dataset) => {
      dataset.category_ids?.forEach((id) => {
        if (!countsMap[id]) {
          countsMap[id] = { count: 0, id, dataset_ids: [] };
        } else {
          countsMap[id] = { ...countsMap[id], dataset_ids: [...countsMap[id].dataset_ids] };
        }

        countsMap[id].dataset_ids.push(dataset.id!);
      });
    });

    for (const category of categories) {
      const id = category.id!;
      if (countsMap[id]) {
        const ids = [...new Set([...countsMap[id].dataset_ids, ...(category.dataset_ids || [])])];
        countsMap[id] = { ...countsMap[id], dataset_ids: ids };
      }
    }
    if (datasetFilters.length) {
      for (const count of Object.values(countsMap)) {
        if (count.id === "other") {
          continue;
        }
        if (!count.dataset_ids.some((id) => datasetFilters.includes(id))) {
          delete countsMap[count.id];
        }
      }
    }

    const result = categories
      .filter((category) => {
        return (
          !datasetFilters.length ||
          (countsMap[category.id!] &&
            countsMap[category.id!].dataset_ids.some((id) => datasetFilters.includes(id))) ||
          (selectedCategories.length === 1 && selectedCategories[0] === category.id)
        );
      })
      .map((category) => {
        const mergedCategory = {
          ...category,
          // @ts-ignore
          count: 0,
          // @ts-ignore
          dataset_ids: category.dataset_ids || [],
          ...countsMap[category.id!],
        };

        if (policyEdit.isEdit && mergedCategory.id === policyEdit.id) {
          mergedCategory.severity = policyEdit.editingPolicyValues.severity;
          mergedCategory.name = policyEdit.editingPolicyValues.name;
          mergedCategory.rule = policyEdit.editingPolicyValues.rule;
        }

        return mergedCategory;
      });

    if (!previewPolicy?.policy) {
      result.push({
        count: countsMap.other?.count || 0,
        dataset_ids: countsMap.other?.dataset_ids || [],
        id: "Uncategorized",
        name: "Unmatched",
      } as any);
    } else if (policyEdit?.isSearch || policyEdit?.isNew) {
      result.push({
        count: countsMap.other?.count || 0,
        dataset_ids: countsMap.other?.dataset_ids || [],
        id: "Uncategorized",
        name: policyEdit.editingPolicyValues.name || "Matching events",
        severity: policyEdit.isNew ? policyEdit.editingPolicyValues.severity : null,
      } as any);
    }

    return result.sort((a, b) => a.count - b.count);
  }, [
    countsData,
    policyEdit,
    datasets,
    datasetFilters,
    categories,
    previewPolicy,
    selectedCategories,
  ]);
  return {
    ...countsQuery,
    complete_results: countsQuery.data?.complete_results,
    isLoading:
      datasetsQuery.isFetching ||
      countsQuery.isLoading ||
      filtersQuery.isFetching ||
      categoriesQuery.isFetching ||
      dateCelQuery.isFetching,
    data,
  };
}

// TODO anyside locations doesn't work on BE, fix this when it's ready on the BE side
export function useLocationsCounts(
  { location }: { location: "any" | "destination" | "source" } = { location: "any" }
) {
  const { apiQuery } = useApiFiltersState();
  const groupMap: Record<"any" | "destination" | "source", string> = {
    any: "any_side.location",
    destination: "destination.location",
    source: "source.location",
  };

  const { dynamicBackfills, dynamicBackfillsQuery } = useDynamicBackfillsSettings();

  return useQuery({
    queryKey: [LocationsCountsKey, getDatasetQueryKey(apiQuery)],

    queryFn: ({ signal }) =>
      api.eventsapi.Service.Aggregations(
        {
          aggregations: {
            locations: {
              type: "group_by",
              group_by: [groupMap[location]],
            },
          },
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ),
    enabled: checkIfAllQueriesAreReady(dynamicBackfillsQuery),
  });
}

export function useDatesCel(enabled = true) {
  const { apiQuery } = useApiFiltersState();
  const q = getDatasetQueryKey(apiQuery);
  const filters = {
    times_filter: q.times_filter || null,
    locations_filters: null,
    users_filter: null,
    side_selector: "" as any,
    category_severity_filter: null,
    datasets_filter: null,
    policies_filter: null,
    status_filter: "any" as any,
    preview_policy: null,
    preview_dataset: null,
  };

  const filtersQuery = useQuery({
    queryKey: ["filtersCelDate", filters.times_filter?.end_time, filters.times_filter?.start_time],

    queryFn: async ({ signal }) => {
      const res = await api.filtersapi.Service.FiltersToCel({ filters }, { signal });
      return res?.cel_string;
    },

    enabled,
  });

  return {
    ...filtersQuery,
    enabled,
  };
}

export function useDatasetCounts({
  onlyCounts,
  isEnabled = true,
  previewPolicy: previewPolicyProps,
}: UseDatasetCounts) {
  const { apiQuery, selectedDatasets, policyEdit, globalFilter, selectedHostnames } =
    useApiFiltersState();
  const { data: datasets = [], ...datasetsQuery } = useDatasetListAll();
  const q = getDatasetQueryKey(apiQuery);
  const selectedDatasetsCountOnly = onlyCounts && selectedDatasets?.[0] !== AllDatasets;

  let categoriesFilter = { ...catergoriesFilterToPolicyFilterV2(q.categories_filter)! };
  let selectedSeverities = apiQuery?.selected_severities || null;
  const selectedSensitivities = apiQuery?.selected_sensitivities || null;
  const previewPolicy = previewPolicyToPreviewPolicyV2(
    previewPolicyProps || q.categories_filter?.preview_category,
    datasets.map((d) => d.id!)
  );

  if (!categoriesFilter.policy_ids && previewPolicy?.policy.id) {
    categoriesFilter = {
      policy_ids: [previewPolicy.policy?.id],
      all_policies: false,
    };
  }
  const isPolicyUncategorizedSelected = categoriesFilter?.policy_ids?.includes("Uncategorized");

  if (selectedSeverities?.length === severityList.length) {
    selectedSeverities = null;
  }

  let datasetFilter = null;
  if (previewPolicy?.policy.dataset_ids?.length && !policyEdit.isSearch) {
    datasetFilter = {
      all_datasets: false,
      dataset_ids: previewPolicy?.policy.dataset_ids,
    };
  } else {
    datasetFilter = {
      all_datasets: false,
      dataset_ids: selectedDatasetsCountOnly
        ? selectedDatasets
        : datasets?.map((d) => d.id!) ?? null,
    };
  }

  if (!previewPolicy?.policy?.dataset_ids?.length) {
    if (previewPolicy?.policy.selection_type === types_SelectionTypeEnum.SelectionTypeDataset) {
      previewPolicy.policy.dataset_ids = datasets?.map((d) => d.id!) ?? null;
    }
  }

  if (categoriesFilter && isPolicyUncategorizedSelected) {
    categoriesFilter = {
      all_policies: false,
      policy_ids: [null, ...(categoriesFilter?.policy_ids || [])],
    };
  }
  const { data: datesCel, ...datesQuery } = useDatesCel();
  const isUncategorizedIncluded = categoriesFilter?.policy_ids?.includes("Uncategorized");
  const { data: filtersCel = "true", ...filtersQuery } = useFiltersCel({
    // times filter should be null, global times filter is applied in the query
    times_filter: null,
    locations_filters: q.locations_filters || null,
    users_filter: q.users_filter || null,
    side_selector: "dataset",
    category_severity_filter: selectedSeveritiesToFilter(
      selectedSeverities,
      isUncategorizedIncluded
    ),
    dataset_sensitivity_filter: selectedSensitivitiesToFilter({
      sensitivities: selectedSensitivities,
      isUncategorizedIncluded,
    }),
    datasets_filter: datasetFilter,
    policies_filter: categoriesFilter,
    status_filter: "any",
    hostnames_filter: [...selectedHostnames],
  });

  const { dynamicBackfills, dynamicBackfillsQuery } = useDynamicBackfillsSettings();

  const countsQuery = useQuery({
    queryKey: [
      DataSetsKey,
      DatasetCountsKey,
      filtersCel,
      getPreviewPolicyKey(previewPolicy),
      datesCel,
      globalFilter,
      getEntityEditKey(policyEdit),
      onlyCounts,
    ],

    queryFn: ({ signal }) =>
      api.eventsapi.Service.Aggregations(
        {
          aggregations: {
            datasets: {
              type: "group_by",
              filter: filtersCel,
              group_by: onlyCounts ? undefined : ["dataset_id", "policy_severity"],
            },
          },
          global_filter: celCombine(
            CELConditionOperator.AND,
            getDatasetNullFilter({ selectedSensitivities }),
            datesCel!,
            globalFilter
          ),
          preview_policy: hackishlyInsertDeviceTypeCondition(previewPolicy?.policy),
          // If onlyCounts has more keys than applied_policies, it means that dataset is being edited
          preview_dataset: hackishlyInsertDeviceTypeCondition(
            onlyCounts && Object.keys(onlyCounts).length > 1 ? onlyCounts : undefined
          ),
          dynamic_backfills: dynamicBackfills,
        },
        { signal }
      ).then((res) => {
        const datasets = {
          complete_results: res.complete_results,
          allDatasets: {
            all_datasets: true,
            category_ids: null,
            count: 0,
            last_modified: "0001-01-01T00:00:00Z",
            name: "",
            query: { rules: null },
            query_version: 0,
            sensitivity: 0,
            severity: {
              null: 0,
              [Severity.Informational]: 0,
              [Severity.Low]: 0,
              [Severity.Medium]: 0,
              [Severity.High]: 0,
              [Severity.Critical]: 0,
            } as Record<Severity | "null", number>,
          },
          countsMap: {} as Record<
            string,
            { severity: Record<Severity | "null", number>; count: number; id: string }
          >,
        };

        for (const val of res.aggregations.datasets.values || []) {
          // severity null or 0-4
          const [datasetId, severity = null] = val.value || [];
          if (!datasets.countsMap[datasetId]) {
            datasets.countsMap[datasetId] = {
              id: datasetId,
              severity: {
                null: 0,
                [Severity.Informational]: 0,
                [Severity.Low]: 0,
                [Severity.Medium]: 0,
                [Severity.High]: 0,
                [Severity.Critical]: 0,
              } as Record<Severity | "null", number>,
              count: 0,
            };
          }

          if (datasetId !== null || !policyEdit.isEdit) {
            datasets.allDatasets.count += val.count;
            const severityVal = policyEdit.isSearch ? "null" : (severity as Severity | "null");
            datasets.allDatasets.severity[severityVal] += val.count;
          }

          // if search show grey result without severity
          if (policyEdit.isSearch) {
            datasets.countsMap[datasetId].severity["null"] = val.count;
          } else {
            datasets.countsMap[datasetId].severity[severity as Severity | "null"] = val.count;
          }
          datasets.countsMap[datasetId].count += val.count;
        }

        return datasets;
      }),

    enabled:
      isEnabled &&
      checkIfAllQueriesAreReady(filtersQuery, datasetsQuery, datesQuery, dynamicBackfillsQuery),
  });
  return {
    ...countsQuery,
    isLoading:
      countsQuery.isFetching ||
      filtersQuery.isFetching ||
      datesQuery.isFetching ||
      datasetsQuery.isFetching,
    isFetching:
      countsQuery.isFetching ||
      filtersQuery.isFetching ||
      datesQuery.isFetching ||
      datasetsQuery.isFetching,
  };
}

export function useIsFullCountsResuts() {
  const { apiQuery } = useApiFiltersState();
  const { data: datasetsData, isLoading: isFetchingDatasets } = useDatasetCounts({});
  const { complete_results, isFetching: isFetchingPolicies } = usePoliciesCounts(apiQuery);
  const { data: usersCountsData, isFetching: isFetchingUsers } = useUsersCounts({
    limit: 20,
    search: "",
  });
  const fetching = isFetchingDatasets || isFetchingPolicies || isFetchingUsers;
  const values = [
    datasetsData?.complete_results,
    complete_results,
    usersCountsData?.pages[0]?.complete_results,
  ];
  const isFullCountsResult = values.every((v) => v !== false) && values.some((v) => v === true);

  return { isFullCountsResult, fetching };
}

export function useDatasetListAll() {
  return useQuery({
    queryKey: [DataSetsAllKey],
    queryFn: ({ signal }) =>
      listDatasets({ signal }).then((el) => el.datasets?.sort(compareByProp("name")) ?? []),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}

export function useCategoryListAll() {
  return useQuery({
    queryKey: [CategoriesAllKey],
    queryFn: ({ signal }) =>
      listCategories({ signal }).then((res) => {
        const categories = res?.categories?.sort(compareByProp("name")) ?? [];
        return {
          ...res,
          categories,
        };
      }),
    staleTime: 5 * 60 * 1000, // 5 minutes
  });
}

export function useDatasetUpsert({
  onSuccess,
}: {
  onSuccess: (req: any, res: any) => Promise<void>;
}) {
  const { apiQuery } = useApiFiltersState();
  const { data } = useDatasetsForList();
  const datasets = data?.records!;
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      dataset,
      templateId,
    }: {
      dataset: riskydashboard.DatasetDTO;
      templateId?: string;
    }) => {
      const shouldCreate = !dataset.id || templateId;

      if (shouldCreate) {
        return createDataset(hackishlyInsertDeviceTypeCondition({ ...dataset, id: templateId }));
      }

      return updateDataset(hackishlyInsertDeviceTypeCondition(dataset));
    },
    onSuccess: async (res, req) => {
      invalidateMultipleQueries(queryClient, [
        [CategoriesAllKey],
        [DataSetsAllKey],
        [MViewStatusKey],
        getAllMatchingListsKey(),
        getEdmEntitiesKeys(),
        ...UserSidePanelKeys,
        [CustomPoliciesKey],
        [PredefinedPoliciesKey],
      ]);
      logEvent(eventsConstants.dashboard.datasets.create);
      await onSuccess?.(res, req);
    },
    onError: (error, __, context: { previousDatasets: any } | undefined) => {
      queryClient.setQueryData([DataSetsKey, getDatasetQueryKey(apiQuery)], {
        records: context?.previousDatasets,
      });
      if (isRbacError(error).result) {
        notification.error({
          message: "Access denied",
        });
      }
    },
    onMutate: async (data) => {
      queryClient.cancelQueries({ queryKey: [DataSetsKey] });
      queryClient.setQueryData([DataSetsKey, getDatasetQueryKey(apiQuery)], {
        records: datasets.map((dataset) => {
          if (dataset.id !== data.dataset.id) {
            return dataset;
          }
          return {
            ...dataset,
            ...data,
          };
        }),
      });

      return { previousDatasets: datasets };
    },
  });
}

export function useDatasetDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteDataset,
    onSuccess: () => {
      invalidateMultipleQueries(queryClient, [
        [CategoriesAllKey],
        [DataSetsAllKey],
        ...UserSidePanelKeys,
        getAllMatchingListsKey(),
        getEdmEntitiesKeys(),
      ]);
      logEvent(eventsConstants.dashboard.datasets.delete);
    },
  });
}

export function useCategoryDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteCategory,
    onSuccess: (_, { id }: { id: string }) => {
      invalidateMultipleQueries(queryClient, [
        [DataSetsAllKey],
        [CategoriesCountsKey],
        getAllMatchingListsKey(),
        getEdmEntitiesKeys(),
        ...UserSidePanelKeys,
      ]);
      logEvent(eventsConstants.dashboard.categories.delete);
      queryClient.setQueryData([CategoriesAllKey], (data?: { categories?: types.Category[] }) => {
        return {
          categories: data?.categories?.filter((category) => category.id !== id),
        };
      });
    },
  });
}

export function useCategoryUpsert({
  onSuccess = () => {},
}: {
  onSuccess: (res: riskydashboard.UpdateCategoryResponse, req: types.Category) => void;
}) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ category, templateId }: { category: types.Category; templateId?: string }) => {
      const shouldCreate = !category.id || templateId;

      if (shouldCreate) {
        return createCategory(hackishlyInsertDeviceTypeCondition({ ...category, id: templateId }));
      }

      return updateCategory(hackishlyInsertDeviceTypeCondition(category));
    },
    onSuccess: async (res, req) => {
      await invalidateMultipleQueries(queryClient, [
        [DataSetsAllKey],
        [CategoriesAllKey],
        [MViewStatusKey],
        getAllMatchingListsKey(),
        ...UserSidePanelKeys,
        getEdmEntitiesKeys(),
      ]);
      logEvent(eventsConstants.dashboard.categories.update);
      queryClient.setQueryData([CategoriesAllKey], (data?: { categories?: types.Category[] }) => {
        return {
          categories: data?.categories?.map((category) => {
            if (category.id === req.category.id) {
              return {
                ...category,
                ...req,
              };
            }
            return category;
          }),
        };
      });
      onSuccess(res, req.category);
    },
  });
}

export const getDatasetQueryKey = (apiQuery: EdgeView_Extended) => {
  return {
    ...pick(apiQuery, [
      "locations_filters",
      "users_filter",
      "times_filter",
      "statuses",
      "categories_filter",
    ]),
    include_irrelevant: true,
    statuses: [
      types_CategoryStatusEnum.CategoryStatusAny,
      types_CategoryStatusEnum.CategoryStatusApproved,
      types_CategoryStatusEnum.CategoryStatusLowRisky,
      types_CategoryStatusEnum.CategoryStatusRiskyOrLow,
      types_CategoryStatusEnum.CategoryStatusRisky,
      types_CategoryStatusEnum.CategoryStatusNeedsReview,
    ],
    include_all_datasets_record: true,
  } as riskydashboard.DatasetsCountV2Request;
};

// returns datasets and counts for datasets in dataset centric view
export function useDatasetsForList(addAllRecord = true) {
  const datasetsCountsQuery = useDatasetCounts({});
  const datasetList = useDatasetListAll();
  const isDeferred = datasetsCountsQuery.isLoading || datasetsCountsQuery.isError;
  const datasetsMap = datasetsCountsQuery.data?.countsMap || {};
  const finalRecords = datasetList.data?.map((el) => ({
    ...datasetsMap[el.id!],
    ...el,
  }));
  if (datasetsCountsQuery.data?.allDatasets && addAllRecord) {
    finalRecords?.unshift(datasetsCountsQuery.data?.allDatasets as any);
  }
  const initialRecords = [...(datasetList?.data ?? [])];
  if (addAllRecord) {
    initialRecords.unshift({
      id: AllDatasets,
      name: AllDatasetsLabel,
    } as any);
  }
  return {
    ...datasetList,
    isDeferred: isDeferred,
    isFetching: datasetsCountsQuery.isFetching || datasetList.isFetching,
    data: {
      records: (isDeferred ? initialRecords : finalRecords) as Array<
        riskydashboard.DatasetDTO & {
          severity: Record<Severity | "null", number>;
          all_datasets: boolean;
          count: number;
        }
      >,
    },
  };
}

// returns categories and counts for categories in dataset centric view
export function useCategoriesForBubbles(apiQuery: EdgeView_Extended) {
  const { policyEdit, datasetEdit } = useApiFiltersState();
  const q = {
    ...omit(apiQuery, ["categories_filter.categories", "categories_filter.all_categories"]),
    statuses: [
      types_CategoryStatusEnum.CategoryStatusApproved,
      types_CategoryStatusEnum.CategoryStatusLowRisky,
      types_CategoryStatusEnum.CategoryStatusRisky,
    ],
    include_irrelevant: Boolean(apiQuery.datasets_filter?.all_datasets),
    include_all_categories_record: false,
    include_uncategorized: true,
    is_search: policyEdit.isSearch,
    is_search_datasets: datasetEdit.isSearch,
  } as GetCategoriesCountsRequestType;

  return useQuery({
    queryKey: [CategoriesKey, q],
    queryFn: async ({ signal }) => {
      return await getCategoriesCounts(q, { signal });
    },
  });
}

export function preloadCategoryListAll(queryClient: QueryClient) {
  return queryClient.prefetchQuery({
    queryKey: [CategoriesAllKey],
    queryFn: ({ signal }) => {
      return listCategories({ signal }).then((res) => {
        return {
          ...res,
          categories: res?.categories?.sort((a, b) => a.name.localeCompare(b.name)),
        };
      });
    },
  });
}

function useDatasetQueryKey({ id }: any) {
  return [DataSetsAllKey, { id }];
}

export function useDataset({ id, value = null }: any) {
  return useQuery({
    queryKey: useDatasetQueryKey({ id }),
    queryFn: async ({ signal }) => {
      if (id === createTypeEnum.search || id === createTypeEnum.new || !id) {
        return value;
      }
      if (id === AllDatasets) {
        return await Promise.resolve({
          ...(value as unknown as object),
          id: AllDatasets,
          name: predefinedDatasetsTitle[AllDatasets],
        } as any);
      }
      return await api.riskydashboard.ServiceInterface.GetDataset({ id }, { signal }).then(
        (el) => el.dataset
      );
    },
  });
}

function useCategoryQueryKey({ id }: any) {
  return [CategoriesAllKey, { id }];
}

export function useCategory({ id, value = null }: { id: string; value: types.Category | null }) {
  return useQuery({
    queryKey: useCategoryQueryKey({ id }),

    queryFn: async ({ signal }) => {
      if (id === createTypeEnum.new || id === createTypeEnum.search) {
        return { category: value as Partial<types.Category> };
      }
      if (id === AllCategories) {
        return Promise.resolve({
          category: {
            id: AllCategories,
            name: AllCategoriesLabel,
          } as Partial<types.Category>,
        });
      }
      return api.riskydashboard.ServiceInterface.GetCategory({ id }, { signal });
    },
  });
}

const fetchLocationsData = (
  context: QueryFunctionContext<[string, riskydashboard.LocationsCountV2Request]>,
  dynamicBackfills: boolean
) => {
  let query: riskydashboard.LocationsCountV2Request = context.queryKey[1];

  if (query.categories_filter?.preview_category?.category) {
    const category = processPreviewPolicy(
      query.categories_filter?.preview_category?.category,
      query.datasets_filter.datasets || []
    );
    query = {
      ...query,
      dynamic_backfills: dynamicBackfills,
      categories_filter: {
        ...query.categories_filter,
        preview_category: {
          ...query.categories_filter.preview_category,
          category: hackishlyInsertDeviceTypeCondition(category),
        },
      },
    };
  }

  if (query.datasets_filter.preview_dataset) {
    query = {
      ...query,
      datasets_filter: {
        ...query.datasets_filter,
        preview_dataset: hackishlyInsertDeviceTypeCondition(query.datasets_filter.preview_dataset),
      },
    };
  }

  return locationCount({ ...query }, { signal: context.signal }).then((data) => {
    return {
      ...data,
      records: data.records?.filter((record) => Boolean(record.count)),
    };
  });
};

export const useLocationsData = (query: riskydashboard.LocationsCountV2Request) => {
  const { dynamicBackfills, isLoading } = useDynamicBackfillsSettings();

  return useQuery({
    queryKey: [LocationsKey, query],
    queryFn: (context: QueryFunctionContext<[string, riskydashboard.LocationsCountV2Request]>) => {
      return fetchLocationsData(context, dynamicBackfills);
    },
    staleTime: 2000,
    placeholderData: keepPreviousData,
    enabled: !isLoading,
  });
};

export const useLocationsTotalData = () => {
  const queryClient = useQueryClient();
  useIsFetching(); // todo used it because of having consistency with widget numbers
  return queryClient.getQueryData([LocationsTotalKey]);
};

// return serializable object
export function getEntityEditKey(entity: Record<string, any>) {
  if (!entity) {
    return null;
  }
  return {
    id: entity.id,
    isEdit: entity.isEdit,
    isEditing: entity.isEditing,
    isNew: entity.isNew,
    isSearch: entity.isSearch,
    editingPolicyValues: omit(entity.editingPolicyValues, "name"),
  };
}

export function useEdgesByIds(ids: string[]) {
  return useQuery({
    queryKey: [EdgesKey, ids],
    queryFn: async ({ signal }) => {
      // edge ids are base64 encoded json
      const edgeIds = ids.map((id) =>
        btoa(
          JSON.stringify({
            source: id,
            destination: id,
            nhops: 1,
            dataset_prefix: "",
            category_prefix: "",
          })
        )
      );
      const res = await api.riskydashboard.ServiceInterface.ListViewByID(
        {
          edge_view_ids: edgeIds,
          dump_edges: true,
          num_preloaded_edges: 0,
          load_all: false,
        },
        { signal }
      );

      return res;
    },
    enabled: Boolean(ids.length),
  });
}
