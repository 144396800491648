export const THEME_ATTR = "data-color-scheme";
const LIGHT_THEME = "light";
const DARK_THEME = "dark";
export const selectDefaultTheme = () => document.body?.setAttribute(THEME_ATTR, LIGHT_THEME);
export const selectDarkTheme = () => document.body?.setAttribute(THEME_ATTR, DARK_THEME);

export type ColorTheme = typeof LIGHT_THEME | typeof DARK_THEME;

export const applyTheme = (theme = "system") => {
  const themeToSet =
    theme === "system"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? DARK_THEME
        : LIGHT_THEME
      : theme;
  document.body?.setAttribute(THEME_ATTR, themeToSet);
};

export const generateThemeAttributes = (theme: ColorTheme) => {
  return {
    [THEME_ATTR]: theme,
  };
};
