import { makeSelectors } from "libs/testing/makeSelectors";

const wrapMonitoring = (selector: any) => "monitoring/" + selector;
export const monitoringSelectors = {
  Flags: {
    Log: wrapMonitoring("log_incident"),
    Notify: wrapMonitoring("notify"),
    Warn: wrapMonitoring("show_warning"),
    Block: wrapMonitoring("blocking"),
  },
  NotifyTarget: wrapMonitoring("recipient"),
  WarnMessage: wrapMonitoring("message"),

  Dialog: wrapMonitoring("dialog"),
  Open: wrapMonitoring("open"),
  Buttons: {
    OK: wrapMonitoring("ok"),
    Cancel: wrapMonitoring("cancel"),
    Clear: wrapMonitoring("clear"),
  },
};

const wrapContentAttributes = (selector: any) => "content-attributes/" + selector;
export const contentAttributesSelectors = {
  List: {
    Item: wrapContentAttributes("list/item"),
    SelectCheckbox: wrapContentAttributes("list/select-checkbox"),
  },
  Filters: {
    Search: wrapContentAttributes("filters/search"),
    OnlySelected: wrapContentAttributes("filters/onlySelected"),
  },
  Select: wrapContentAttributes("select"),
  Modal: {
    ApplyButton: wrapContentAttributes("modal/apply-button"),
  },
};

const wrapSearch = (selector: any) => "search/" + selector;
export const searchSelectors = {
  ToggleSearch: wrapSearch("toggle-button"),
  CloseSearch: wrapSearch("close-button"),
  Name: wrapSearch("name"),
  CardList: wrapSearch("criteria-list"),
  SavedSearchSelector: wrapSearch("saved-searches-selector"),
  SavedSearchSelectorPopover: wrapSearch("saved-searches-popover"),
  KeywordSearchInput: wrapSearch("keyword-search"),
  KeywordSearchItem: wrapSearch("keyword-search/item"),
  KeywordSearchSelectedItem: wrapSearch("keyword-search/selected-item"),
  ContentAttributeSelect: wrapSearch("content/content-attribute"),
  SavedList: wrapSearch("saved/list"),
  SavedSearchSelectedItem: wrapSearch("saved/selected"),
  SavedSearchItem: wrapSearch("saved/item"),
  SaveSearchMenu: wrapSearch("saved/item-menu"),
  EnabledIcon: wrapSearch("saved/icon-enabled"),
  MenuItems: {
    Edit: wrapSearch("saved/edit"),
    Delete: wrapSearch("saved/delete"),
  },
  FindSaved: wrapSearch("saved/find"),
  Breadcrumbs: wrapSearch("saved/breadcrumbs"),
  Buttons: {
    Search: wrapSearch("execute"),
    Save: wrapSearch("save"),
    Clear: wrapSearch("clear"),
  },
};

export const graphSelectors = makeSelectors("graph", {
  Graph: {
    MainGraph: "",
    Edge: "",
    Node: "",
    SELECTED_NODE_POLYGON: "",
    IndirectPath: "",
    DirectPath: "",
    OpenMenu: "",
    CollapseButton: "",
  },
  Menu: "",
  MenuItems: {
    SearchFrom: "",
    SearchTo: "",
    ExpandBy: "",
  },
  Tabs: "",
  Buttons: {
    Group: "",
    Home: "",
    Refresh: "",
    ToggleEdges: "",
    ToggleEdgesGroup: "",
    ZoomIn: "",
    ZoomOut: "",
    ZoomGroup: "",
    fitToScreen: "",
  },
});

const wrapNotifications = (selector: any) => "notification/" + selector;
export const notificationsSelectors = {
  Container: wrapNotifications("container"),
  Message: wrapNotifications("message"),
};

export const timeSeriesBarChart = makeSelectors("timeSeriesBarChart", {
  bar: "",
  monthAxis: "",
  daysAxis: "",
});

export const endpointsSelectors = makeSelectors("endpoints", {
  List: {
    Table: "",
    Search: "",
    Refresh: "",
  },
  Filters: "",
  Tabs: {
    Endpoints: "",
    Installers: "",
  },
  Installers: {
    All: "",
  },
  Export: "",
  ExportAll: "",
});

export const listViewSelectors = makeSelectors("list-view", {
  Row: "",
  ExpandBtn: "",
  CollapseBtn: "",
  Select: {
    All: "",
    Single: "",
  },
  SubTable: {
    Graph: "",
    Table: "",
    Row: "",
    ExpandHiddenRows: "",
    CollapseHiddenRows: "",
  },
  SearchFlowBtn: "",
  ExportBtn: "",
  ExportAllBtn: "",
  OpenAiSummaryBtn: "",
  AiSummaryModal: "",
});

export const usersSelectors = makeSelectors("users", {
  List: {
    Item: "item",
  },
  Filters: {
    Input: "input",
    Selector: "selector",
  },
  Profile: {
    Delete: "delete",
  },
  Invite: {
    Roles: "",
  },
});

export const alertSelectors = makeSelectors("alerts", {
  Select: "",
  Search: "",
  Table: "",
  Details: "",
  DetailsDrawer: "",
  Export: "",
  ExportAll: "",
});

export const sensorsHealthSelectors = makeSelectors("endpoint-sensors-health", {
  Table: "",
});

export const modalFormSelectors = makeSelectors("ModalForm", {
  Submit: "submit",
});

export const datePickerSelectors = makeSelectors("datepicker", {
  input: {
    container: "",
    from: "",
    to: "",
  },
  ranges: {
    "day": "",
    "24Hours": "",
    "week": "",
    "7Days": "",
    "month": "",
    "30Days": "",
    "90Days": "",
    "180Days": "",
    "year": "",
    "anytime": "",
  },
  ok: "",
});

export const insightsSelectors = makeSelectors("insights", {
  actions: {
    exportMenuBtn: "",
    exportMenu: {
      csv: "",
    },
    addToReport: "",
    openEvents: "",
    openUnusualLocations: "",
  },
});

export const sensitiveLocationsSelectors = makeSelectors("sensitiveLocations", {
  createForm: {
    buttons: {
      back: "",
      next: "",
    },
  },
  trustedDestination: {
    filters: {
      searchByName: "",
      searchByDestination: "",
    },
  },
});

export const dashboardSelectors = makeSelectors("dashboard", {
  actions: {
    switchToDataSprawl: "",
    switchToEvents: "",
  },
  datePicker: {
    panel: "",
    anytime: "",
    today: "",
    yesterday: "",
    last7Days: "",
    last30Days: "",
    open: "",
  },
  appliedFilters: {
    panel: "",
    filter: "",
  },
  datasetPreview: {
    dialog: "",
    close: "",
    editName: "",
    setupPolicies: "",
    delete: "",
    save: "",
    form: {
      panel: "",
      preview: "",
    },
  },
  searthForm: {
    togglePathDelimiterMatching: "",
  },
  dataSprawl: {
    panel: "",
    filter: "",
    chartWithPanelContainer: "",
    currentDatasetAndCategoryTypesChart: "",
    chartContainer: "",
    categoriesChart: "",
    categoryTypes: {
      needsReview: "",
      risks: "",
      approved: "",
    },
    categories: {
      panel: "",
      add: "",
      select: "",
      labelSelect: "",
      edit: "",
    },
    datasets: {
      panel: "",
      add: "",
      edit: "",
      select: "",
      selectAll: "",
    },
  },
  trendsOverTime: {
    panel: "",
    filter: "",
    chart: "",
    timeRange: "",
  },
  locations: {
    itemContextMenu: "",
    exportContextMenu: "",
    export: "",
    panel: "",
    filter: "",
    select: "",
    pagination: "",
  },
  users: {
    searchButton: "",
    searchInput: "",
    panel: "",
    filter: "",
    select: "",
    pagination: "",
  },
  listViewPanel: {
    share: "",
    openPanelButton: "",
    closePanelButton: "",
  },
  datasetEditForm: {
    form: "",
  },
  categoryEditForm: {
    form: "",
  },
  addUsersForm: {
    form: "",
  },
  addFlowsForm: {
    form: "",
  },
  changesTimeline: {
    filter: "",
    panel: "",
    users: "",
    events: "",
  },
  templateLibrary: {
    policies: {
      list: "",
      search: "",
    },
  },
});

export const updatedSinceSelectors = makeSelectors("updatedSince", {
  renew: "",
});

export const navigationSelectors = makeSelectors("navigation", {
  home: "",
  dashboard: "",
  dashboardv2: "",
  dataflows: "",
  incidents: "",
  analytics: "",
  reports: "",
  sensors: {
    cloud: "",
    endpoint: "",
  },
  auditLog: "",
  preferences: "",
  administration: "",
  profile: "",
  insiders: "",
  listsMatching: "",
  aiSettings: "",
  insights360: "",
  objects: "",
});

export const directoriesSelectors = makeSelectors("directories", {
  list: {
    id: "",
    name: "",
    panel: "",
    row: {
      id: "",
      name: "",
    },
  },
});

export const incidentsSelectors = makeSelectors("incidents", {
  filters: {
    open: {
      count: "",
    },
    assigned: {
      count: "",
    },
    resolved: {
      count: "",
    },
    ignored: {
      count: "",
    },
  },
  list: {
    panel: "",
    selectAll: "",
    row: {
      select: "",
    },
    pagination: "",
  },
  incident: {
    share: "",
    history: "",
    changeStatus: "",
    markIgnored: "",
    markResolved: "",
    assignIncident: "",
    markUnresolved: "",
    timeline: {
      panel: "",
    },
    comment: {
      edited: "",
      addComment: "",
      edit: "",
      delete: "",
    },
    markResolvedMenu: {
      valid: "",
      dataMislabeled: "",
      legitimateUseOfData: "",
      userExempt: "",
      customComment: "",
      customCommentTextarea: "",
      invalidCustomComment: "",
    },
    flagAiButton: "",
    aiFlagCheckbox: "",
    aiFlagComment: "",
    closeWithCommentSubmitButton: "",
    closeWithCommentBackButton: "",
    copyEmail: "",
    openInsights: "",
  },
  selectionBar: {
    selection: "",
  },
});

export const universalSearchSelectors = makeSelectors("universal-search", {
  searchInput: "",
  list: {
    expand: "",
    collapse: "",
    loadMoreFields: "",
    field: (field: any) => field,
    item: "",
    fieldLoadMore: "",
  },
});

export const rbacUsersSelectors = makeSelectors("rbac-users", {
  invite: "",
  actions: (email: string) => email,
  changeScope: "",
  changeRole: "",
  delete: "",
  deleteDialog: "",
  resetAuthProvider: "",
  resetAuthProviderDialog: "",
  resetMFA: "",
  resetMFADialog: "",
  resetPassword: "",
  resetPasswordDialog: "",
});

export const scopeSelectors = makeSelectors("scope", {
  scopeDialog: "",
  scopeDeleteDialog: "",
  scopeDialogSave: "",
  grid: "",
  actions: (scope: string) => scope,
  create: "",
  edit: "",
  delete: "",
  changeScopeSave: "",
});

export const apiKeysSelectors = makeSelectors("api-key", {
  dialog: "",
  deleteDialog: "",
  dialogSave: "",
  grid: "",
  actions: (apiKey: string) => apiKey,
  create: "",
  edit: "",
  delete: "",
  copyTokenConfirm: "",
});

export const rolesSelectors = makeSelectors("roles-page", {
  actionCell: (roleName: string) => `role-action-${roleName}`,
  actionEdit: "action-edit",
  actionDelete: "",
  create: "",
  roleDialog: "",
  roleDialogSave: "",
  changeRoleSave: "",
});

export const irmSelectors = makeSelectors("irm", {
  clearRiskScore: "",
  addRemoveFromUserRiskGroup: "",
  deleteUserRiskGroup: "",
  editUserRiskGroup: "",
  goToEvents: "",
  goToIncidents: "",
  goToVA: "",
  goToDataflows: "",
  userHistoryTab: "",
  allUserDetailsTab: "",
});

export const endpointSensorsSelectors = makeSelectors("endpoint-sensors", {
  downloadInstallersSelect: "",
  downloadInstallersWindowsTab: "",
  downloadInstallersMacOsTab: "",
  downloadInstallersLinuxTab: "",
  downloadInstallerBtn: "",
  copyInstallerConfigBtn: "",
});

export const aiRemediationsSelectors = makeSelectors("ai-remediations", {
  markAsDoneButton: (actionType: string) => `mark-as-done-${actionType}`,
  contactRemediation: {
    button: "",
  },
  contactDialog: {
    generateButton: "",
    mailtoLink: "",
    generatedMessage: "",
  },
  datasetRemediation: {
    button: "",
  },
  policyRemediation: {
    button: "",
  },
});

export const policiesGridSelectors = makeSelectors("policies", {
  grid: "",
  deleteDialog: "",
  deleteMenuItem: "",
  rowActionsMenu: "",
  editPolicyMenuItem: "",
  disablePolicyMenuItem: "",
  duplicatePolicyMenuItem: "",
  exportPoliciesButton: "",
  bulkDeletePoliciesButton: "",
  createPolicyButton: "",
  customizeColumnsButton: "",
  duplicatePolicyConfirmButton: "",
  deletePolicyConfirmButton: "",
  togglePolicyDisabledButton: "",
  togglePolicyDisabledIcon: "",
  saveChangesButton: "",
  singlePolicyDateSelectorButton: "",

  bulkDeleteDialog: {
    confirmButton: "",
    cancelButton: "",
  },
});

export const aiConfigSelectors = makeSelectors("ai-remediations", {
  saveConfiguration: "",
});

export const gridSelectors = makeSelectors("grid", {
  filters: {
    appliedFiltersButton: "",
    clearAllFiltersButton: "",
    clearIndividualFilterPillButton: "",
  },
  columnSettings: {
    button: "",
  },
  checkboxFilters: {
    loadMoreOptionsButton: "",
  },
});

export const selector = (selector: any) => `[data-testid="${selector}"]`;
