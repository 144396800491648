import axios from "axios";
import { IOnedriveTenant } from "./interfaces";
import { ExternalAuth } from "./ExternalAuth";

class OnedriveAuth extends ExternalAuth<IOnedriveTenant> {
  private tenant?: IOnedriveTenant;

  isAuthorized() {
    return !!this.tenant;
  }

  getUser = () => {
    if (this.isAuthorized()) {
      return this.tenant!;
    }

    return axios
      .get<IOnedriveTenant>("/onedrive/login/info", {
        meta: {
          ignoreUnauthorized: true,
        },
      } as any)
      .then((response) => {
        this.tenant = response.data;
        return this.tenant!;
      });
  };

  login() {
    return this.authorize("/onedrive/login-consent", "Onedrive Login Consent").then(this.getUser);
  }

  logout() {
    return axios.get<IOnedriveTenant>("/onedrive/logout").then((response) => {
      this.tenant = undefined;
      return response;
    });
  }
}

export const onedriveAuth = new OnedriveAuth();
